.banner {
    height: 490px;
}


.banner {
  position: relative;
}

.banner::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(to right, rgba(53, 78, 153, 0.7),rgba(86, 162, 95, 0.7)); /* Adjust the gradient as needed */
  z-index: 1;
}

.banner-content {
  position: relative;
  z-index: 2;
  top: -22rem;
  text-align: left;
  font-size: 4rem; /* Adjust the font size as needed */
  font-weight: bold;
  color: white;
}

@media (min) {
  
}

.banner-content h1 {
  text-shadow: 2px 2px 6px rgba(0, 0, 0, 0.5); /* Adjust the shadow as needed */
}