.blog-card {
    display: flex;
    flex-direction: column;
    padding: 20px;
    margin: 10px;
    width: 100%;
    max-width: 600px;
    text-align: left;
    border-radius: 8px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    position: relative;
}

.blog-card img {
    width: 100%;
    height: 15rem;
    object-fit: cover;
    margin-top: 10px;
    border-radius: 4px;
}

.blog-card h2 {
    font-size: 1.5rem;
}

.blog-card p {
    font-size: 0.9rem;
    margin-top: 10px;
    color: #737373;
    margin-bottom: 10px;
}

.blog-card a {
    color: #354e99;
    font-weight: bold;
    text-decoration: none;
}

.blog-card a:hover {
    color: #56a25f;
    transition: color 0.2s ease;
}

.blog-card a:not(:hover) {
    transition: color 0.2s ease;
}

.read-more-container {
    margin-top: auto;
}