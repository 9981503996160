/* BlogPost.css */

.blog-post {
  max-width: 1000px;
  margin: 0 auto;
  padding: 20px;
  background-color: #ffffff;
  border-radius: 8px;
  font-family: 'Arial', sans-serif;
}

.blog-post h1 {
  font-size: 2.5rem;
  margin-bottom: 20px;
  color: #333333;
}


.blog-post img {
  max-width: 100%;
  height: auto;
  border-radius: 8px;
  margin-bottom: 20px;
}

.blog-post .content {
  font-size: 1.125rem;
  line-height: 1.6;
  color: #444444;
}

.blog-post .author-info {
  display: flex;
  align-items: center;
  margin-top: 40px;
  padding-top: 20px;
  border-top: 1px solid #eeeeee;
}

.blog-post .author-info img {
  border-radius: 50%;
  width: 50px;
  height: 50px;
  margin-right: 15px;
}

.blog-post .author-info .author-details {
  font-size: 0.875rem;
  color: #777777;
}

.blog-post .related-posts {
  margin-top: 40px;
}

.blog-post .related-posts h2 {
  font-size: 1.5rem;
  margin-bottom: 20px;
  color: #333333;
}

.blog-post .related-posts .related-post {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

.blog-post .related-posts .related-post img {
  width: 80px;
  height: 80px;
  border-radius: 8px;
  margin-right: 15px;
}

.blog-post .related-posts .related-post .related-post-title {
  font-size: 1rem;
  color: #444444;
}

.blog-post .social-sharing {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.author-info {
    color: #777777;
    justify-content: space-between;
}

.author-details .date {
    margin: auto;
}

.blog-post .social-sharing button {
  background-color: #007bff;
  color: #ffffff;
  border: none;
  padding: 10px 20px;
  margin: 0 5px;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.blog-post .social-sharing button:hover {
  background-color: #0056b3;
}

.like-section {
  display: flex;
  align-items: center;
  margin-top: 20px;
}

.heart-button {
  background: none;
  border: none;
  font-size: 2rem;
  cursor: pointer;
  margin-right: 10px;
  transition: transform 0.2s ease;
}

.heart-button:hover {
  transform: scale(1.2);
}

.like-section span {
  font-size: 1.25rem;
  color: #444444;
}

.blog-content h2 {
    font-size: 1.5rem;
    margin-bottom: 20px;
}

.blog-content ul li {
    font-size: 1.1rem;
    margin-bottom: 1rem;
}

.blog-content p {
    font-size: 1.1rem;
    margin-bottom: 1rem;
}

.blog-content h3 {
    text-align: center;
    font-size: 1.2rem;
}

.blog-post {
  text-align: left;
}