.menu-button {
    display: inline-block;
    position: absolute;
    bottom: 20px;
    
}

.menu-card-container:hover {
    border-color: #56a25f;
    transition: border-color 0.4s ease;
}



.menu-card-container:not(hover) {
    transition: border-color 0.4s ease;
}

.mechanika:nth-last-child(2) {
    grid-column-end: -2;
}
