.onepage-content ul {
    list-style-type: disc;
    padding-left: 40px; 
    margin-bottom: 20px;
}
  
.onepage-content  ul ul {
    list-style-type: circle;
}

.onepage-content h1 {
    font-size: 33px;
    font-weight: 600;
    margin-bottom: 20px;
}

.onepage-content p {
    font-size: 18px;
    margin-bottom: 20px;
}

.onepage-content h2 {
    font-size: 24px;
    font-weight: 600;
    margin-bottom: 20px;
}

.onepage-content li {
    font-size: 18px;
    margin-bottom: 2px;
}

.onepage-content h3 {
    font-size: 20px;
    font-weight: 600;
    margin-bottom: 20px;
}

.onepage-footer h2 {
    font-size: 24px;
    font-weight: 600;
    margin-top: 40px;
    margin-bottom: 30px;
    text-align: center;
}

.onepage-content {
    text-align: left;
}