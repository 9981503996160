.header {
    margin: 0;
}

.menu-links li a {
    color: #1A1A1A;
    position: relative;
    text-decoration: none;
}

.menu-links li a::after {
    content: '';
    position: absolute;
    width: 0;
    height: 2px;
    display: block;
    margin-top: 5px;
    right: 0;
    background: #56a25f;
    transition: width 0.3s ease;
}

.menu-links li a:hover {
    color: #354e99;
    transition: w 0.3s ease;
}

.menu-links li a:hover::after {
    width: 100%;
    left: 0;
    background: #56a25f;
}

.menu-link {
    cursor: pointer;
}