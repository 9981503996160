.slider-container {
  width: 100%;
  overflow: hidden;
  position: relative;
}

.slider {
  display: flex;
  flex-wrap: nowrap;
  animation: infinite-scroll 15s linear infinite;
}

.logos {
  display: flex;
  flex-wrap: nowrap;
}

.logos li {
  margin: 0 16px;
}

.logos img {
  max-width: 150px; /* Adjust the size as needed */
}

@keyframes infinite-scroll {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-130%);
  }
}

@keyframes infinite-scroll-mobile {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-440%);
  }
}

@media (max-width: 1200px) {
  .slider {
    animation: infinite-scroll 20s linear infinite; /* Adjust duration for large tablets */
  }

  @keyframes infinite-scroll {
    0% {
      transform: translateX(0);
    }
    100% {
      transform: translateX(-200%);
    }
  }
}

@media (max-width: 800px) {
  .slider {
    animation: infinite-scroll-mobile 10s linear infinite; /* Speed up the animation on mobile */
  }

  @keyframes infinite-scroll-mobile {
    0% {
      transform: translateX(0);
    }
    100% {
      transform: translateX(-440%);
    }
  }
}

@media (max-width: 500px) {
  .slider {
    animation: infinite-scroll-mobile 15s linear infinite; /* Adjust duration for small mobile devices */
  }

  @keyframes infinite-scroll-mobile {
    0% {
      transform: translateX(0);
    }
    100% {
      transform: translateX(-600%);
    }
  }
}